.main_about {
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  padding: 5rem;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.image_sec_about {
  display: flex;
  flex-direction: column;
  width: 40%;
  gap: 10px;
  text-align: center;
  align-items: center;
}
.content_sec_about {
  display: flex;
  flex-direction: column;
  width: 60%;
  gap: 10px;
  text-align: center;
  align-items: center;
}
.content_sec_about h1 {
  width: 100%;
  text-align: right;
  font-size: 50px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
}
.content_sec_about p {
  width: 100%;
  text-align: right;
  font-size: 1.5rem;
  line-height: 1.5;
  font-family: "Roboto", sans-serif;
}
.image_sec_about img {
  height: 250px;
  border-radius: 5px;
}

@media (max-width: 1000px) and (min-width: 750px) {
  .content_sec_about {
    padding: 3.5rem;
    width: 70%;
  }
  .image_sec_about {
    width: 30%;
  }
  .image_sec_about img {
    height: 200px;
  }
}

@media (max-width: 749px) and (min-width: 200px) {
  .main_about {
    padding: 2.5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .content_sec_about {
    width: 100%;
  }
  .image_sec_about {
    width: 100%;
  }
  .image_sec_about img {
    height: 200px;
  }
  .content_sec_about h1,
  .content_sec_about p {
    text-align: center;
  }
}
