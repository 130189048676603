.home_main {
  height: 100vh;
  display: flex;
  flex-direction: row;
  padding: 5rem;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.name_sec {
  width: 70%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
}
.image_sec {
  width: 30%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.name_sec h1 {
  font-size: 6rem;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: lighter;
  letter-spacing: 2px;
}
.typingeffect {
  height: 4rem;
  font-size: 2rem;
}
.contact_icons {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.image_sec img {
  height: 350px;
  border-radius: 50%;
}
@media (max-width: 900px) and (min-width: 801px) {
  .home_main {
    height: auto;
  }
  .name_sec h1 {
    font-size: 5rem;
    letter-spacing: 2px;
  }
  .image_sec img {
    height: 250px;
    border-radius: 50%;
  }
}

@media (max-width: 800px) and (min-width: 630px) {
  .home_main {
    padding: 3rem;
    height: auto;
  }
  .name_sec h1 {
    font-size: 4.5rem;
    letter-spacing: 2px;
  }
  .image_sec img {
    height: 250px;
    border-radius: 50%;
  }
  .typingeffect {
    height: 4rem;
    font-size: 1.5rem;
  }
}
@media (max-width: 629px) and (min-width: 200px) {
  .home_main {
    padding: 2.5rem;
    flex-direction: column;
    height: auto;
    gap: 1rem;
  }
  .name_sec {
    width: 100%;
    gap: 1.2rem;
  }
  .image_sec {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .name_sec h1 {
    font-size: 3rem;
  }
  .image_sec img {
    height: 250px;
  }
  .typingeffect {
    height: 2.5rem;
    font-size: 1rem;
  }
}

@media (max-width: 629px) and (min-width: 450px) {
  .image_sec img {
    height: 250px;
    border-radius: 50%;
  }
}

@media (max-width: 449px) {
  .image_sec img {
    height: 220px;
    border-radius: 50%;
  }
}
