@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
body {
  font-family: Inter, -apple-system, system-ui, "Segoe UI", Helvetica, Arial,
    sans-serif;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
/* Hide scrollbar for Chrome, Safari and Opera */
*::-webkit-scrollbar {
  display: none;
}

button {
  appearance: none;
  backface-visibility: hidden;
  background-color: #27ae60;
  border-radius: 8px;
  border-style: none;
  box-shadow: rgba(39, 174, 96, 0.15) 0 4px 9px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 1.2rem;
  font-weight: 500;
  text-transform: capitalize;
  letter-spacing: normal;
  line-height: 1.5;
  outline: none;
  overflow: hidden;
  padding: 5px 10px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transform: translate3d(0, 0, 0);
  transition: all 0.3s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: top;
  white-space: nowrap;
}

button:hover {
  background-color: #1e8449;
  opacity: 1;
  transform: translateY(0);
  transition-duration: 0.35s;
  box-shadow: rgba(67, 185, 116, 0.2) 0 6px 12px;
}

button:active {
  transform: translateY(2px);
  transition-duration: 0.35s;
}
.main_components {
  margin-top: 4rem;
}
html {
  scroll-behavior: smooth;
}
